/**
 * Popups
 *
 * @package BIDXCMS
 * @version 1
 * @author Alok Jain
 */


$(document).ready(function(){

       //$("#videocustom").css("background","url(" + $("#banner_poster_image").val() + ")");
		if(document.getElementsByClassName('yoututbeplayer').length > 0){
		$(".yoututbeplayer").YTPlayer({
			useOnMobile:true,
			coverImage:$("#banner_poster_image").val(),
		});

		$(".yoututbeplayer").on("YTPStart",function(e){
			$(".banner_wrapper_video").removeClass("banner-loader-class");
		});
		}


		// ==============================
		// Show Login Popup
		// ============================== //
		$('body').on('click','.menu_log',function(e) {
				e.preventDefault();
				$('.popup-overlay').hide();
				$('#login-overlay.popup-overlay').show();
				$('#login-popup').find('#login_name').focus();
		});

		// ==============================
		// Show Registration Popup
		// ============================== //
		$('body').on('click','.menu_registration',function(e) {
				e.preventDefault();
				$('.popup-overlay').hide();
				$('#register-overlay.popup-overlay').show();
		});

		// ==============================
		// Show Reset Password Popup
		// ============================== //
		$('body').on('click','.forgot_password',function(e) {
				e.preventDefault();
				$('.popup-overlay').hide();
				$('#recover-overlay.popup-overlay').show();
		});

		// ==============================
		// Close Popup
		// ============================== //
		jQuery('body').on('click','.closePopup',function() {
			jQuery('.popup-overlay').hide();
		});

		///////******* Detail Page Popups *******////////

		// ==============================
		// Request More Information Popup
		// ============================== //
		if($(".detailform").length > 0 && $(".request_info").length > 0 ) {
			$( ".detailform" ).dialog({
				autoOpen: false,
				width: '400px',
				height: 'auto',
		      	modal: true
			});

			$( ".request_info" ).click(function( event ) {
				$( ".detailform" ).dialog( "open" );
				event.preventDefault();
			});
		}



		if($(".frontend-notice-and-warning-popup-widget").length){

			$(".frontend-notice-and-warning-popup-widget").dialog({
				width: '30%',
				closeOnEscape: true,
				modal: true
			});
			$(".frontend-notice-and-warning-popup-widget").dialog("open");

			$(".frontend-notice-and-warning-popup-widget").parent().find(".ui-dialog-titlebar-close").css("z-index","999");
			$(".frontend-notice-and-warning-popup-widget").parent().find(".ui-dialog-titlebar-close").css("top","15px");
			$(".frontend-notice-and-warning-popup-widget").parent().find(".ui-dialog-titlebar-close").css("outline","none");
			$(".frontend-notice-and-warning-popup-widget").parent().find(".ui-dialog-title").css("display","none");

	  }

		// ==============================
		// Contact Popup
		// ============================== //
		if($(".contact_agent_popup").length > 0 ) {
			$( ".contact_agent_popup" ).dialog({
				autoOpen: false,
				width: '400px',
				height: 'auto',
		      	modal: true
			});

			$( ".contact_popup" ).click(function( event ) {
				$( ".contact_agent_popup" ).dialog( "open" );
				event.preventDefault();
			});
		}

		// ==============================
		// Request Showing Popup
		// ============================== //
		if($(".schedule_showing_popup").length > 0 ) {
			$( ".schedule_showing_popup" ).dialog({
				autoOpen: false,
				width: '400px',
				height: 'auto',
		    modal: true
			});

			var schedule_param = GetParameterValues('popup');
			if(schedule_param == 'showing'){
				setTimeout(function(){ $( ".schedule_showing_popup" ).dialog( "open" ); }, 500);
			}

			$( ".schedule_showing" ).click(function( event ) {
				$( ".schedule_showing_popup" ).dialog( "open" );
				event.preventDefault();
			});
		}

		// ==============================
		// Property Offer Popup
		// ============================== //
		if($(".detail-submit-offer-popup").length > 0 ) {
			$( ".detail-submit-offer-popup" ).dialog({
				autoOpen: false,
				width: '400px',
				height: 'auto',
		        modal: true
			});

			var offer_param = GetParameterValues('popup');
			if(offer_param == 'offer'){
				setTimeout(function(){ $( ".detail-submit-offer-popup" ).dialog( "open" ); }, 500);
			}

			$( ".detail_submit_offer" ).click(function( event ) {
				$( ".detail-submit-offer-popup" ).dialog( "open" );
				event.preventDefault();
			});
		}

		// ==============================
		// Request Showing Popup
		// ============================== //
		//view images popup
		if($(".images_popup").length > 0 ) {
			$( ".images_popup" ).dialog({
				autoOpen: false,
				width:1000,
				height: 800,
		      	modal: true
			});

			$( ".view_images_popup" ).click(function( event ) {
				$( ".images_popup" ).dialog( "open" );
				event.preventDefault();
			});

			$( ".images_popup img" ).click(function() {
				$( ".images_popup" ).dialog("close");
				$(".bidx_image_main").attr('src', $(this).attr('src'));
			});
		}

		if($("#concessions-compensations").length > 0 ) {

			let page = 1; // Page counter to keep track of the current page

	 
			$("#concessions-compensations .search-form").validationEngine()

			$('#concessions-compensations #loadMore').click(function() {
					$('#concessions-compensations #loading').show(); // Show loading indicator
					$('#concessions-compensations #loadMore').hide();

					$.ajax({
							url: '/compensations.html', // Your server endpoint to get more listings
							method: 'GET',
							data: { page: page },
							dataType: 'json',
							success: function(response) {
									// Assuming response is an array of HTML strings or elements
									$('#concessions-compensations #listings').append(response.data);
									if(response.loadMore){
										$('#concessions-compensations #loadMore').show();
									}
									page++; // Increment the page counter
							},
							error: function() {
									alert('An error occurred while loading more listings.');
							},
							complete: function() {
									$('#concessions-compensations #loading').hide(); // Hide loading indicator
							}
					});
			});
			// ==============================
			// Contact Agent
			// ============================== //
			if($("#contactAgentCommDialogs").length > 0 ) {
	
					// Initialize the jQuery UI dialog
					$("#contactAgentCommDialogs").dialog({
						autoOpen: false,
						modal: true,
						width: 400,
						open: function() {
							$(document).on('mousedown.ui-dialog', function(event) {
									var dialog = $("#contactAgentCommDialogs").data('ui-dialog');
									var isClickInsideDialog = $(event.target).closest(dialog.uiDialog).length > 0;
	
									if (!isClickInsideDialog) {
											$("#contactAgentCommDialogs").dialog("close");
									}
							});
						},
						close: function() {
								$(document).off('mousedown.ui-dialog');
						}
	
	
					});
					// Handle the button click event
					$('#concessions-compensations').on('click', '.contact_agent_comm',  function() {
							const name = $(this).data("name");
							const email = $(this).data("email");
							const phone = $(this).data("phone");
							const phoneFormat = $(this).data("phone-format");	
 							$("#contactAgentCommDialogs #agent-name").text(name);				
							$("#contactAgentCommDialogs #agent-phone").html (`<a href="tel:+1${phone}" >Phone: +1 ${phoneFormat}</a>`);
							$("#contactAgentCommDialogs #agent-email").html(`	<a href="mailto:${email}" >Email: ${email}</a>`);							 		
							$("#contactAgentCommDialogs").dialog("open");
					});
			}
		}

	/**
	 * Add Are You Human spam check box
	 */
		$('#register-popup input[type=submit]')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');
		$('#login_form input[type=submit]')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');
		$('.contact_form input[type=submit]')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');
		$('.signup_form input[type=submit]')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');
		$('.cma_form_container input[type=submit]')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');
		$('#landing-form-btn-submit')
			.before('<div class=\"form-check captcha_test\"><span class=\"form-check-label\">Check this box if you are a real person:</span> <input type="checkbox" name="human" class=\"form-check-input\" aria-label="are you a real person" /></div>');

	// ==============================
	// Schedule A Showing Form Popup
	// ============================== //
	if($(".schedule-showing-popup").length > 0 ) {
		$( ".schedule-showing-popup-widget" ).dialog({
			autoOpen: false,
			width: '400px',
			height: 'auto',
			modal: true
		});

		$( ".schedule-showing-popup" ).click(function( event ) {
			$( ".schedule-showing-popup-widget" ).dialog( "open" );
			event.preventDefault();
		});
	}

	if($('.schedule-form-container').hasClass('is_open_schedule_pop')){
		$( ".schedule-showing-popup-widget" ).dialog( "open" );
	}


	///for checking login in schedule showing popup..
	$(".send-schdeule-login-ajax").click(function(event) {
		$.ajax({
			type : "POST",
			dataType : "JSON",
			url : "/account_check_schedule_ajax.html",
			success : function(response){
				if(response.is_login_pop == '1'){
					$('.popup-overlay').hide();
					$('#login-overlay.popup-overlay').show();
				}
			}
		});
	});

	$("input[name='subscriber_cc_number']").on('keyup',function(){
		$(this).val($(this).val().replace(/[^0-9]/g,""));
	});

	$("form[name='the-contact-form']").each(function() {
		$(this).validate({
			rules: {
				thefirstname: "required",
				thelastname: "required",
				human: "required",
				thephone: {
					required  : true,
					minlength: 7,
					"regex": /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
				},
				theemail: {
					required: true,
					email: true
				}

			},
			messages: {
				human: "Please check this box",
				thefirstname: "Please enter first name",
				thelastname: "Please enter last name",
				theemail: "Please enter a valid email address",
				thephone: "Please enter a valid phone number"
			},
			submitHandler: function(form) {
				$(this).form.submit();
			}
		});
	});

	jQuery.validator.addMethod(
		"regex",
		 function(value, element, regexp) {
			 if (regexp.constructor != RegExp)
				regexp = new RegExp(regexp);
			 else if (regexp.global)
				regexp.lastIndex = 0;
				return this.optional(element) || regexp.test(value);
		 },"erreur expression reguliere"
	  );

	/* make ajax on the search page on state select*/
	$( "#searchstate select" ).change(function() {
		get_state_and_counties_result("state");
	});
	/* make ajax on the search page on county select*/
	$( "#searchcounty select" ).change(function() {
		get_state_and_counties_result("county");
	});

	/* Check the site type is agent then hide the trial period option*/
	if($('#price_list_id').val() == 'Agent1'){
		$('#trial_period_id').hide();
		$('#subscriber_trial').prop('checked', false);
	}
	/* Check the site type and show/hide the trial period option*/
	$('#price_list_id').on('change', function() {
		var value = this.value;
		if(value == 'Agent1'){
			$('#trial_period_id').hide();
			$('#subscriber_trial').prop('checked', false);
		} else {
			$('#trial_period_id').show();
		}
	});

	$('.listing-messages-customer-dashboard .listing-message-open-chat').click(function()
	{
		var target = $(this).data('target');
		if(target != ''){
				$('.listing-messages-customer-dashboard .customer-listing-message-widget-main').css('display', 'none');
				$('.listing-messages-customer-dashboard #'+target).css('display', 'block');
				$('.listing-messages-customer-dashboard .listing-message-open-chat').css("background-color", "#F4F8FB");
				$(this).css("background-color", "#71B5F3");
		}
	});

	$(document).on("click", "#copylistingurlpopup", function (e) {
		var lisitnurl = $(this).data("url");
		var dummy = $('<input>').val(lisitnurl).appendTo('body').select();
		dummy.focus();
		document.execCommand('copy');
		$(".copylistingurlpopup-tooltiptext").show();
		setTimeout(function(){
			$(".copylistingurlpopup-tooltiptext").hide();
		},1000);
		
	});
	if ($("#sliderfront").length > 0) {
		var img_duration = parseInt($("#images-transition-time").val());
		var set_height = parseInt($('#banner-slider-wrap').height() ? $('#banner-slider-wrap').height() : 500);
		$("#sliderfront").carouFredSel({
			responsive: true,
			auto: true,
			width: "100%",
			height: set_height,
			circular: true,
			scroll: {
				items: null,
				fx: "fade",
				duration: img_duration,
			},
		});
	}
	if ($(".banner-outside-wrap-carousel").length > 0) {
		$(".banner-outside-wrap-carousel").carouFredSel({
			responsive: true,
			auto: true,
			width: "100%",
			//height: 500,
			circular: true,
			scroll: {
				items: null,
				fx: "fade",
				duration: 500,
			},
		});
	}

	if ( window.location !== window.parent.location)
    { 
		window.parent.postMessage(window.location.pathname, '*');
    }
	var query_string_action = getParameterByName('action');
	if(query_string_action == 'preview') {
		$(document).on('click','a',function(event) {
			event.preventDefault();
			var newURL = $(this).attr('href');
			if(newURL.indexOf('?') > -1){
				window.location = newURL+'&action=preview&dr=1&t='+Math.random();
			} else {
				window.location = newURL+'?action=preview&dr=1&t='+Math.random();
			}			
		});
		$('form').submit(function(e) {
			e.preventDefault();
		});
	}		
	
});


function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/* make ajax on the search page on state and county*/
function get_state_and_counties_result($type){
	var state = $( "#searchstate select" ).val();
	var counties = $( "#searchcounty select" ).val();
	if($type == "state"){
		counties = [];
		counties = ["99"];
	}
	if(!state){
		state = "none";
	}
	var county_label = "County";
	if (state == "LA") {
		var county_label = "Parish";
	}
	$(".countylabel").html(county_label);
	$.ajax({
		type : "POST",
		dataType : "JSON",
		data: { action : 'get_counties_results', state:state, counties:counties },
		url : "/search.html",
		success : function(response){

			$.each(response,function(key, val){
				var param = 'search'+key+'dropdown';
				if($type == key){
					return true;
				}

				$('#'+param).html('');

				if(val){
					if(key == "county"){
						$('#searchcountydropdown').html('<option value="99">ALL COUNTIES</option>');
					}
					$.each(val,function(k,value){

							$('#'+param).append($('<option>',{
								value: value.value,
								text : value.name,
							}));
					});
				}else{
					$('#'+param).html('<option value="">No result  found.</option>');
				}
			});
		}
	});
}
//Copy to clipboard
$(".copy-link").click(function(e) {
		e.preventDefault();
		$(".share-link").show();
		$(".share-link").focus();
		$(".share-link").select();
		document.execCommand("copy");
		$(".share-link").hide();
});

function GetParameterValues(param) {
	var url = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
	for (var i = 0; i < url.length; i++) {
		var urlparam = url[i].split('=');
		if (urlparam[0] == param) {
			return urlparam[1];
		}
	}
}

function dosum() {
	var mi = document.temps.IR.value / 1200;
	var base = 1;
	var mbase = 1 + mi;
	for (i=0; i  < document.temps.YR.value * 12; i++) {
	base = base * mbase;
	}
	document.temps.PI.value = floor(document.temps.LA.value * mi / ( 1 - (1/base)))
	document.temps.MT.value = floor(document.temps.AT.value / 12)
	document.temps.MI.value = floor(document.temps.AI.value / 12)
	var dasum = document.temps.LA.value * mi / ( 1 - (1/base)) +
	document.temps.AT.value / 12 +
	document.temps.AI.value / 12;
	document.temps.MP.value = floor(dasum);
}
function floor(number) {
	return Math.floor(number*Math.pow(10,2))/Math.pow(10,2);
}

jQuery(function(){


	if(document.getElementById('bgndVideo')){
	var vimeo = $("#bgndVideo").vimeo_player({
			containment: '#vimeid',
			showControls:true,
			autoPlay:true,
			mute:true
	});
	
	vimeo.on("VPStart",function(e){
		$(".vimeo-wrap").removeClass("banner-loader-class");
		setTimeout(function(){
			$("#banner-vimeo-one-slider-wrap").hide();
			vimeo.v_play();
			$(".banner_wrapper_video").removeClass("banner-loader-class");
		},1500);
	});
	}

	if(document.getElementById('bgndVideoBehind')){
	var vimeo = $("#bgndVideoBehind").vimeo_player({
			containment: '.banner-behind-header',
			showControls:true,
			autoPlay:true,
			mute:true
	});
	
	vimeo.on("VPStart",function(e){
		$(".vimeo-wrap").removeClass("banner-loader-class");
		setTimeout(function(){
			vimeo.v_play();
			$(".banner_wrapper_video").removeClass("banner-loader-class");
		},1500);
	});
	}

});

var vid = document.getElementById("customvideo");
if(vid != undefined || vid != null) {
	vid.onplay  = function() {
		$(".banner_wrapper_video").removeClass("banner-loader-class");
	}
}
