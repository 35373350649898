
$(document).ready(function(){
		
	////code for validation of any form.
	if ($("form").hasClass("validate-form")){
		$(".validate-form").validationEngine();
	}
	
	///code to submit the add contact form and validate for duplicate email and phone
	$(".submit_button").click(function(e) {
		var email = $('.profile_email').val();
		var phone = $('.profile_phone').val();
		$('.email-error').text('');
		$('.phone-error').text('');
		if($(".validate-form").validationEngine('validate')){
			$.ajax({
				type: "POST",
				url: 'system_transmanager_ajax.html',
				dataType : 'JSON',
				data: { action : 'duplicate_contacts', email : email, phone : phone, customer_id: $('#contactid').val() },
				success: function(result) {
					if(result.status == 'success'){
						$("#customer_profile_form").submit();
					}else{
						if(result.status =='error-phone'){
							$('.phone-error').text('Customer with provided phone already exists.');
							$('.profile_phone').val('').focus();
						}else{
							$('.email-error').text('Customer with provided email already exists');
							$('.profile_email').val('').focus();
						}
					}
				}
			});
		}
	});
	
	if ($(".form-field").hasClass("datepicker")){
		$(".datepicker").datetimepicker({
			timepicker:false,	
			format: 'Y/m/d',
			minDate: new Date(),     
		});			
	}
	
	if ($(".form-field").hasClass("datetimepicker")){
 		var dateObj = new Date();
		$(".datetimepicker").datetimepicker({
			minDate: dateObj, 
			yearStart : dateObj.getUTCFullYear(),
			monthStart : dateObj.getUTCMonth(),
 			format: 'm/d/Y g:i a', 
			scrollMonth :false,
		});			
		$(".datetimepicker").keypress(function(event) {event.preventDefault();});
	}
	
	$(".priceinput").change(function() {
	  var price =  $(this).val().replace(/\D/g,'');
	  $(this).val(price);
	});
	
	if($('#defaultOpenSchedule').length > 0){
		if($('#defaultOpenSchedule').data('status')  != '1' ){	
			schedulePopup($('#defaultOpenSchedule').data('scheduleid'),$('#defaultOpenSchedule').data('showingtime'),$('#defaultOpenSchedule').data('address'));
		}
	}
	
	//for rescheduling the showing..
	$(".action-reschedule-showing").click(function(event) {
		if($(this).data('status') != '1'){											   
     		 schedulePopup($(this).data('scheduleid'),$(this).data('showingtime'),$(this).data('address'));
		}
	});
});	

function schedulePopup(scheduleid,pdate,address){
	if(scheduleid){	
		$('#schedule_id').val(scheduleid);
		$('#thepreferredtime').val(pdate);
		$('.prop-address-re').text(address);
		$(".action-reschedule-showing-widget").dialog();
		$(".action-reschedule-showing-widget").dialog("open");
		$(".action-reschedule-showing-widget").dialog({
			width: '30%',
			closeOnEscape: false,
			close:function(){
					$('#schedule_id').val('');	
					$('#thepreferredtime').val('');
				}
		});
	}
}
